globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"zcWBfwMycwd4aNqGus1nc"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  enabled: process.env.NEXT_PUBLIC_RELEASE !== 'svg-web:v0.0.0',
  debug: process.env.NODE_ENV === 'development',
  dsn: 'https://f9cd32866d524365af3f27c7604ac698@glitchtip.riespo.com/9',
  release: process.env.NEXT_PUBLIC_RELEASE,
  autoSessionTracking: false,
  sendClientReports: false,
});
